<template>
  <component
    :is="icon"
  />
</template>

<script setup lang="ts">
import type { Component } from 'vue'

const props = defineProps<{ name: string }>()
const icon = computed(() => defineAsyncComponent(async (): Promise<Component> => import(`../assets/icons/${props.name}.svg`)))
</script>
